//react
import { useCallback, useRef, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Backdrop from "@mui/material/Backdrop";

import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
//utils & helper
import {
  axiosConfig,
  httpErrorHandler,
  notificationsHandler,
} from "../../utils/helpers";
//3rd party
import { useSnackbar } from "notistack";
//hooks
import useAxios from "../../hooks/useAxios";

const CustomDialog = styled(Dialog)(({ theme }) => ({
  padding: "32px",
  "& .MuiDialogContent-root": {
    padding: "0",
    marginBottom: "56px",
  },
  "& .MuiDialogTitle-root": {
    textAlign: "left",
    padding: "0",
    marginBottom: "12px",
  },
  "& .MuiDialogContentText-root": {
    textAlign: "left",
  },
  "& .MuiDialogActions-root": {},
  "& .MuiDialog-paper": {
    padding: "32px",
    borderRadius: "12px",
  },
}));
function ActivateCreditConsent({
  isActivateCreditsOpen,
  setIsActivateCreditsOpen,
  creditId,
  supervisorId,
  setData,
  ...props
}) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isRequestProcessing, SetIsRequestProcessing] = useState(false);
  //axios instance
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();

  const handleClose = useCallback(() => {
    setIsActivateCreditsOpen(false);
  }, [setIsActivateCreditsOpen]);
  const activateCredits = useCallback(async () => {
    try {
      SetIsRequestProcessing(true);
      const config = axiosConfig({
        method: "PUT",

        uri: "/accounts/manager/supervisors/activate-credits",
        data: {
          credit_id: creditId,
          user_id: supervisorId,
        },
      });
      const response = await axiosInstance.current({
        ...config,
      });
      console.log(response.data);
      setData((prev) => {
        return { ...prev, status: "completed", credit: response.data };
      });
      notificationsHandler(
        "success",
        "Credits Activated Successfully",
        enqueueSnackbar,
        closeSnackbar
      );

      SetIsRequestProcessing(false);
      handleClose();
    } catch (error) {
      if (error && error.message !== "canceled") {
        SetIsRequestProcessing(false);
        handleClose();
      }
      httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
    }
  }, [
    enqueueSnackbar,
    closeSnackbar,
    handleClose,
    creditId,
    supervisorId,
    setData,
  ]);

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isActivateCreditsOpen}
      onClose={handleClose}
    >
      <CustomDialog open={isActivateCreditsOpen} onClose={handleClose}>
        <DialogTitle
          sx={{
            ".MuiDialogTitle-root": { textAlign: "center", display: "none" },
          }}
        >
          Mark payment as complete & activate credits
        </DialogTitle>
        <DialogContent sx={{ textAlign: "center" }}>
          <DialogContentText>
            Make sure that the payment is completed before activating the
            credits. Once the credits are activated, they cannot be
            deacitivated.
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Typography
            onClick={handleClose}
            sx={{
              cursor: "pointer",
              fontWeight: "500",
              fontSize: "18px",
              lineHeight: "23px",

              color: "#959595",
            }}
          >
            Back
          </Typography>
          <Button
            variant="contained"
            onClick={(e) => activateCredits()}
            disableElevation
            disabled={isRequestProcessing}
            sx={{
              backgroundColor: "#00B448",
              ":hover": {
                backgroundColor: "#038537",
              },
            }}
            autoFocus
          >
            Activate Credits
          </Button>
        </DialogActions>
      </CustomDialog>
    </Backdrop>
  );
}

export default ActivateCreditConsent;
