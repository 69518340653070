//react
import { useEffect, useState, useCallback, useRef } from "react";
//mui
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
//internal
import BasicTable from "../components/BasicTable";
import SearchHeader from "../components/search_header/SearchHeader";
import ComponentLoader from "../components/loader/ComponentLoader";
//hooks
import useAxios from "../hooks/useAxios";
//utils & helper
import { axiosConfig, httpErrorHandler } from "../utils/helpers";
//3rd party
import { useSnackbar } from "notistack";

import CustomPagination from "../components/CustomPagination";

const cols = [
  {
    name: "Name",
    accessor: "root",
    getAccessor: (el) => `${el.first_name} ${el.last_name}`,
  },
  {
    name: "Joined On",
    accessor: "date_joined",
  },
  {
    name: "Orgainization",
    accessor: "profile",
    getAccessor: (el) => el?.organization_name,
  },
  {
    name: "Email",
    accessor: "email",
  },
  {
    name: "Consumer",
    accessor: "profile",
    getAccessor: (el) => el?.consumers,
  },
  {
    name: "Credits Purchased",
    accessor: "profile",
    getAccessor: (el) => el?.credit_summary?.purchased?.credits_added,
  },
];

const filterByMenu = [
  {
    label: "Email",
    value: "email",
  },
  {
    label: "Name",
    value: "first_name",
  },
];

const checkSearchKey = (searchKey) => {
  return Object.values(searchKey).every((value) => {
    if (value === null) {
      return true;
    }
    return false;
  });
}; //if searchKey contains all searchBy values null, it will return the true else false
function MainUsers(props) {
  const [usersData, setUsersData] = useState(null);
  const [searchKey, setSearchKey] = useState({
    first_name: null,
    email: null,
    created_on_from: null,
    created_on_to: null,
    credit_request_status: null,
  });
  //axios instance
  const axiosInstance = useRef();
  axiosInstance.current = useAxios();

  const paginationCall = useRef(false);
  const [count, setCount] = useState(0);
  const [reload, setReload] = useState(false);
  const [page, setPage] = useState(1);
  const [fetchingUsers, setFetchingUsers] = useState(false);
  const [error, setError] = useState(null);
  const [searching, setSearching] = useState(false);
  // const [paginationCall, setPaginationCall] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const PAGE_SIZE = 10;

  //fetching the users data
  const getSupervisors = useCallback(
    async ({ searchParams, pageValue, abortController }) => {
      try {
        const config = axiosConfig({
          method: "GET",
          params: {
            page: pageValue ? pageValue : page,
            page_size: PAGE_SIZE, // TODO: CHANGE THIS
            ...searchParams,
          },
          uri: "/accounts/manager/supervisors/",
        });
        const response = await axiosInstance.current({
          ...config,
          signal: abortController.signal,
        });
        setUsersData(response.data.results);
        setCount(response.data.count);
        paginationCall.current = false; //do not remove
        // if (!searching) {
        //   originalState.current = response.data.results;
        // }
      } catch (error) {
        if (error && !error.message === "canceled") setError(error);
        httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
      }
    },
    [page, enqueueSnackbar, closeSnackbar]
  );
  useEffect(() => {
    const abortController = new AbortController();
    if (checkSearchKey(searchKey) && !paginationCall.current) {
      setFetchingUsers(true);
      getSupervisors({ abortController })
        .then(() => {
          if (!abortController?.signal?.aborted) setFetchingUsers(false);
        })
        .catch((error) => {
          if (!abortController?.signal?.aborted) setFetchingUsers(false);
        });
    } // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => abortController.abort();
  }, [reload, getSupervisors, searchKey]); //don't add paginationCall dependency

  useEffect(() => {
    const abortController = new AbortController();
    if (!checkSearchKey(searchKey) && !paginationCall.current) {
      setPage(1);
      setSearching(true);
      getSupervisors({ searchParams: searchKey, pageValue: 1, abortController })
        .then(() => {
          if (!abortController?.signal?.aborted) setSearching(false);
        })
        .catch(() => {
          if (!abortController?.signal?.aborted) setSearching(false);
        });
    } else if (paginationCall.current && checkSearchKey(searchKey)) {
      setSearching(true);
      getSupervisors({ pageValue: page, abortController })
        .then(() => {
          if (!abortController?.signal?.aborted) setSearching(false);
        })
        .catch((error) => {
          if (!abortController?.signal?.aborted) setSearching(false);
        });
    } else if (paginationCall.current || !checkSearchKey(searchKey)) {
      //searchKey is not null this Will do search also for the given key with and pagination on it.
      setSearching(true);
      getSupervisors({
        searchParams: searchKey,
        pageValue: page,
        abortController,
      })
        .then(() => {
          if (!abortController?.signal?.aborted) setSearching(false);
        })
        .catch((error) => {
          if (!abortController?.signal?.aborted) setSearching(false);
        });
    }
  }, [searchKey, getSupervisors, reload, page]);

  return (
    <Paper
      sx={{ background: "#f9f9f9", height: "100%", pb: "4rem", ...props.sx }}
      elevation={0}
    >
      {fetchingUsers || error ? (
        <ComponentLoader
          loading={fetchingUsers}
          error={error}
          sx={{ background: "transparent" }}
          retry={() => {
            setReload((prev) => {
              return !prev;
            });
          }}
        />
      ) : (
        <>
          <Grid container>
            <Grid item xs={12}>
              <SearchHeader
                disableAddButton
                sx={{ mb: "2rem" }}
                setSearchKey={setSearchKey}
                filterByMenu={filterByMenu}
                searchBarPlaceholder="Search By Name, Email"
              />
            </Grid>
            {searching || error ? (
              <ComponentLoader
                loading={searching}
                error={error}
                sx={{ background: "transparent" }}
                retry={() => {
                  setReload((prev) => {
                    return !prev;
                  });
                }}
              />
            ) : (
              <Grid item xs={12}>
                <BasicTable
                  cols={cols}
                  rows={usersData ? usersData : []}
                  loading={fetchingUsers}
                  linkKey="id"
                  linkTo="/main-users"
                />
              </Grid>
            )}
          </Grid>

          {usersData && (
            <CustomPagination
              disabled={fetchingUsers}
              last_page_no={Math.ceil(count / PAGE_SIZE)}
              limit={(usersData && usersData.length) || 0}
              handlePaginationChange={(_, value) => {
                setPage(value);
                paginationCall.current = true;
              }}
            />
          )}
        </>
      )}
    </Paper>
  );
}

export default MainUsers;
