//react
import { useCallback, useRef } from "react";
//utils and helper
import {
  axiosConfig,
  httpErrorHandler,
  notificationsHandler,
} from "../../utils/helpers";
//internal
//hooks
import useAxios from "../../hooks/useAxios";
//mui
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";

//3rd party
import { useSnackbar } from "notistack";
//3rd party
import { Formik, Form } from "formik";
import * as yup from "yup";
import { FORM_VALIDATION } from "../../utils/formValidation";
// internal
import TextField from "../forms/FormComponents/TextField";
import ButtonWrapper from "./FormComponents/Button";

const LOCAL_FORM_VALIDATION = yup.object().shape({
  purpose: FORM_VALIDATION["purpose"],
  numberOfCredits: FORM_VALIDATION["numberOfCredits"],
  totalPrice: FORM_VALIDATION["totalPrice"],
});
const FORM_INITIAL_VALUES = {
  purpose: "",
  numberOfCredits: 0,
  totalPrice: 0,
};
export default function AddDemoCreditForm(props) {
  const { supervisorId, closePopUp, setCreditsData, setCount } = props;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const axiosInstance = useRef();
  axiosInstance.current = useAxios();

  const assignDemoCredits = useCallback(
    async (totalCredits, totalPrice, purpose) => {
      try {
        const config = axiosConfig({
          method: "POST",
          uri: "/credits/",
          data: {
            added: totalCredits,
            supervisor: supervisorId,
            payment: {
              mode: "DEMO",
              price: totalPrice * 100,
            },
            purpose: purpose,
          },
        });
        const response = await axiosInstance.current({
          ...config,
        });

        setCreditsData((prev) => {
          return [...prev, response.data];
        });
        notificationsHandler(
          "success",
          "Demo Credits Assigned Successfully",
          enqueueSnackbar,
          closeSnackbar
        );
        setCount((prev) => prev + 1);
        closePopUp();
      } catch (error) {
        if (error && !error.message === "canceled")
          httpErrorHandler(error, enqueueSnackbar, closeSnackbar);
      }
    },
    [
      supervisorId,
      setCreditsData,
      closePopUp,
      enqueueSnackbar,
      closeSnackbar,
      setCount,
    ]
  );

  return (
    <Paper sx={{ ...props.sx }} elevation={0}>
      <Formik
        initialValues={FORM_INITIAL_VALUES}
        validationSchema={LOCAL_FORM_VALIDATION}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          await assignDemoCredits(
            values.numberOfCredits,
            values.totalPrice,
            values.purpose
          );
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, isValid, values }) => {
          return (
            <Form>
              <Stack>
                <Box sx={{ mb: "28px" }}>
                  <InputLabel htmlFor="number-of-credits" sx={{ mb: "12px" }}>
                    Number of Credits*
                  </InputLabel>
                  <TextField
                    type="number"
                    id="number-of-credits"
                    name="numberOfCredits"
                    sx={{ width: "100%" }}
                    inputProps={{
                      sx: {
                        background: "#F6F6F6",
                      },
                    }}
                  />
                </Box>
                <Box sx={{ mb: "28px" }}>
                  <InputLabel htmlFor="price-per-credit" sx={{ mb: "12px" }}>
                    Total Price{"  "}
                    <span style={{ fontSize: "12px" }}>
                      ($
                      {values.numberOfCredits
                        ? parseFloat(
                            values.totalPrice / values.numberOfCredits
                          ).toFixed(3)
                        : 0}{" "}
                      / credit)
                    </span>
                  </InputLabel>
                  <TextField
                    id="price-per-credit"
                    variant="outlined"
                    sx={{
                      width: "100%",
                      ".MuiOutlinedInput-root": { background: "#F6F6F6" },
                    }}
                    inputProps={{
                      sx: {
                        background: "#F6F6F6",
                      },
                    }}
                    name="totalPrice"
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box sx={{ mb: "28px" }}>
                  <InputLabel htmlFor="purpose" sx={{ mb: "12px" }}>
                    Purpose*
                  </InputLabel>
                  <TextField
                    id="purpose"
                    name="purpose"
                    sx={{ width: "100%" }}
                    inputProps={{
                      sx: {
                        background: "#F6F6F6",
                      },
                    }}
                  />
                </Box>

                <Stack
                  direction="row"
                  justifyContent={"space-between"}
                  alignItems="center"
                  mb="10px"
                >
                  <Button
                    variant="text"
                    sx={{
                      color: "#6D6D6D",
                      px: "16px",
                      py: "12px",
                    }}
                    onClick={(e) => closePopUp()}
                  >
                    Cancel
                  </Button>
                  <ButtonWrapper
                    disableElevation
                    disabled={isSubmitting || !isValid}
                    sx={{
                      px: "16px",
                      py: "12px",
                      maxWidth: "max-content",
                      mt: 0,
                    }}
                  >
                    Assign Demo Credits
                  </ButtonWrapper>
                </Stack>
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </Paper>
  );
}

AddDemoCreditForm.defaultProps = {
  supervisorId: null,
  setCreditsData: () => {},
  closePopUp: () => {},
};
