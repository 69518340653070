import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import errorImgFive from "../../assets/error/error-colored-red.svg";

import nbLogo from "../../assets/logo/Neurobit-Score-Logo-Transparent 1.svg";
//3rd party
import { useNavigate } from "react-router-dom";

function Error500() {
  const navigate = useNavigate();
  const CustomForm = () => (
    <>
      <Stack
        direction="column"
        py={5}
        style={{
          width: "100%",
          maxWidth: "700px",
          // paddingBottom: "40px",
        }}
      >
        <Typography
          style={{
            fontSize: "64px",
            fontWeight: "700",
            lineHeight: "83.33px",
            letterSpacing: "0em",
            textAlign: "left",
            color: "#DB9D64",
          }}
        >
          Error 500
        </Typography>

        <Stack
          direction="column"
          py={2}
          style={{
            width: "100%",
            maxWidth: "700px",
          }}
        >
          <Typography
            style={{
              fontSize: "48px",
              fontWeight: "700",
              lineHeight: "62.5px",
              letterSpacing: "0em",
              textAlign: "left",
              color: "#000000",
              width: "100%",
            }}
          >
            Server error{" "}
          </Typography>

          <Typography
            style={{
              fontSize: "32px",
              fontWeight: "500",
              lineHeight: "41.66px",
              letterSpacing: "0em",
              textAlign: "left",
              color: "#959595",
              width: "100%",
            }}
          >
            Our servers are busy. Try again later.
          </Typography>
        </Stack>
        <Stack
          direction="column"
          py={2}
          style={{
            width: "100%",
            maxWidth: "700px",
          }}
        >
          <Button
            disableElevation
            size="small"
            variant="contained"
            sx={{
              maxWidth: "250px",
              textTransform: "none",
              fontSize: "28px",
              fontWeight: "500",
              backgroundColor: "#2F7EC7",
            }}
            onClick={() => {
              navigate(-1);
            }}
          >
            Go Back
          </Button>
        </Stack>
      </Stack>
    </>
  );

  return (
    <Grid
      container
      style={{
        height: "100vh",
        backgroundColor: "#F5F6FA",
      }}
    >
      <Grid item xs={12} lg={6}>
        {/* <Box
          px={2}
          style={{
            height: "50vh",
            backgroundColor: "#F5F6FA",
            display: "flex",
            // alignItems: "flex-start",
            // justifyContent: "center",
          }}
        > */}
        <Stack
          direction="column"
          style={{
            // width: "100%",
            // maxWidth: "700px",
            alignItems: "flex-start",
            display: "flex",

            justifyContent: "center",
            padding: "30px 0 0 30px",
          }}
        >
          <img src={nbLogo} alt="Trial" height={69} width={123} />
        </Stack>
        {/* </Box> */}

        <Box
          py={7}
          style={{
            height: "70vh",
            backgroundColor: "#F5F6FA",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            paddingLeft: "32px",
          }}
        >
          <CustomForm />
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        lg={6}
        style={{
          backgroundColor: "#F5F6FA",
          height: "100%",
          width: "100%",
        }}
      >
        <Box
          px={2}
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Stack
            justifyContent="center"
            alignItems="center"
            spacing={4}
            style={{
              width: "100%",
              maxWidth: "800px",
            }}
          >
            <img src={errorImgFive} alt="Trial" width="100%" />
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Error500;
